var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.getAdminFormFillUpSubject.elective_subject),function(elective_subject,index){return _c('div',{key:index},[(
        _vm.paper_group_set == null ||
          _vm.paper_group_set ===
            elective_subject[0].core_paper.paper_group.paper_group_set
      )?_vm._t("default",function(){return [_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v(" Elective Subject "),(elective_subject[0].core_paper.paper_group.show_name)?_c('small',[_vm._v("(Group "+_vm._s(index)+")")]):_vm._e()]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('span',[_vm._v("select any "+_vm._s(elective_subject[0].core_paper.paper_group.number_of_paper)+" of "+_vm._s(elective_subject.length))]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":elective_subject,"item-key":"paper_code","hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',[_c('th'),_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
                'column sortable',
                _vm.pagination.descending ? 'desc' : 'asc',
                header.value === _vm.pagination.sortBy ? 'active' : ''
              ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_vm._v(" "+_vm._s(header.text)+" ")],1)})],2)]}},{key:"items",fn:function(props){return [(
              !props.item.prev_grade &&
                props.item.core_paper.paper_type_info.name == 'elective' &&
                _vm.alt_paper_code.indexOf(props.item.paper_code) == -1 &&
                (!_vm.group_wise_no_of_paper[index] ||
                  _vm.group_wise_no_of_paper[index].length <
                    props.item.core_paper.paper_group.number_of_paper ||
                  Object.values(_vm.group_wise_no_of_paper[index]).indexOf(
                    props.item.paper_code
                  ) != -1)
            )?_c('tr',[_c('td',{attrs:{"active":props.selected},on:{"click":function($event){props.selected = !props.selected}}},[_c('v-checkbox',{attrs:{"input-value":props.selected,"primary":"","hide-details":""},on:{"click":function($event){_vm.test = 1}}})],1),_c('td',[_vm._v(_vm._s(props.item.core_paper.paper_name))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(" "+_vm._s(props.item.core_paper.paper_code)+" ")]),_c('td',{staticClass:"text-xs-center"},[_vm._v(" "+_vm._s(props.item.core_paper.paper_types.paper_type)+" ")]),_c('td',{staticClass:"text-xs-left my-0 py-0"},[((Number(props.item.core_paper.incourse_marks) > 0 || Number(props.item.core_paper.paper_type) == 6) && (Number(_vm.getAdminFormFillUpSubject.form_fill_up.ff_criteria) == 1))?_c('span',[_c('v-text-field',{attrs:{"name":"incourse_marks","label":"Incourse Mark","prepend-icon":"edit","rules":[
                  _vm.validationRules.min(
                    Number(props.item.incourse_marks),
                    Number(props.item.core_paper.minimum_incourse_marks)
                  ),
                  _vm.validationRules.max(
                    Number(props.item.incourse_marks),
                    Number(props.item.core_paper.incourse_marks)
                  )
                ]},on:{"input":function($event){return _vm.updateIncourse(props.item)}},model:{value:(props.item.incourse_marks),callback:function ($$v) {_vm.$set(props.item, "incourse_marks", $$v)},expression:"props.item.incourse_marks"}})],1):_c('span',[_vm._v(_vm._s(props.item.incourse_marks))])])]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}):_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }